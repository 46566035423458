var myModal;
  
document.addEventListener("turbolinks:load", function() {
	var modal = document.getElementById('modal');
	if(modal) {
	    var options = {
	      backdrop: true,
	      keyboard: false
	    };
	    myModal = new bootstrap.Modal(modal, options);
	    if(JSON.parse(sessionStorage.getItem("modal_hidden"))) {
	    } else {
	    	myModal.show();
	    }
	    modal.addEventListener('hidden.bs.modal', function (event) {
        	sessionStorage.setItem("modal_hidden", true);
		})
	};
});

document.addEventListener("turbolinks:before-cache", function() {
	var modal = document.getElementById('modal');
	if(modal) {
		myModal.dispose();
	};
})