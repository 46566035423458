document.addEventListener("turbolinks:load", function() {
	const activity_select = document.querySelector("#nhi_activity");

	if(activity_select !== null) {
		value = activity_select.options[activity_select.selectedIndex].value
		initSelection(value);

		activity_select.addEventListener("change", () => {
			value = activity_select.options[activity_select.selectedIndex].value
			initSelection(value);
		});
	};

	const lkai_select = document.querySelector('#nhi_lkai_id');
	const lkai_entity = document.querySelector('#nhi_entity');
	const iframe = document.querySelector('#iframe');

	if(lkai_select !== null) {
		value = lkai_select.options[lkai_select.selectedIndex].value;
		text = lkai_select.options[lkai_select.selectedIndex].text;
		replaceInput(text, lkai_entity);
		replaceLink(value, text, iframe);
		lkai_select.addEventListener("change", () => {
			value = lkai_select.options[lkai_select.selectedIndex].value;
			text = lkai_select.options[lkai_select.selectedIndex].text;
			// initSelect(rec_lkai_select, rec_lkai_field);
			replaceLink(value, text, iframe);
			replaceInput(text, lkai_entity);
		});
	}	
});

function initSelection(value) {
	pabean_ttu = document.querySelectorAll("#pabeanttu");
	pabean = document.querySelectorAll("#pabean");
	cukai = document.querySelectorAll("#cukai");
	ttu = document.querySelectorAll("#ttu");

	if(value == "Impor/Ekspor") {
		pabean.forEach((element) => {
			element.style.display = "block";

		});
		// pabean_ttu.style.display = "block";
	} else {
		pabean.forEach((element) => {
			element.style.display = "none";

		});
		// pabean_ttu.style.display = "none";
	};

	if(value == "Cukai") {
		cukai.forEach((element) => {
			element.style.display = "block";

		});
		pabean_ttu.forEach((element) => {
			element.style.display = "none";

		});
	} else {
		cukai.forEach((element) => {
			element.style.display = "none";

		});
		pabean_ttu.forEach((element) => {
			element.style.display = "block";

		});
	};

	if(value == "Barang Tertentu") {
		ttu.forEach((element) => {
			element.style.display = "block";

		});
		// pabean_ttu.style.display = "block";
	} else {
		ttu.forEach((element) => {
			element.style.display = "none";

		});
		// pabean_ttu.style.display = "none";
	};

}

function replaceLink(value, text, iframe) {
	link = document.getElementById('lkai_print');
	link.href = `/lkais/${value}/print.pdf`;
	link.target = '_blank';
	link.text = `Show New Tab`;
	iframe.src = `/lkais/${value}/print.pdf`;
	if(value === "") {
		link.href = `#`;
		link.text = `Select First`;
		link.target = '_blank';
		iframe.src = ''
	}
	// console.log("replace value ")
}

function replaceInput(text, target) {
	array = text.split(" | ")
	if(array.length > 0) {
		entity = `${array[2]} | ${array[3]}`;
	} else {
		entity = "Pilih LKAI dulu"
	};
	target.value = entity;
	// console.log(`replace entity`);
}