import Rails from "@rails/ujs";
window.Rails = Rails;

// install first with yarn add apexcharts
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

var list = [["people_card"], ["company_card"], ["issue_card"], ["intel_dashboard1_card"], ["intel_dashboard2_card"]];

function tampilin(e) {
	var target = document.getElementById(e[0]);
	if(target) {
		Rails.ajax({
			url: `/home/${e[0]}`,
			type: "get",
			success: function(data) {
				var content = new XMLSerializer().serializeToString(data);
				target.innerHTML = content;
				var tooltips = document.querySelectorAll("#tooltip");
				tooltips.forEach( 
					(element) => new bootstrap.Tooltip(element)
				);
				var scrollbars = document.querySelectorAll('.scrollbar-overlay');
				scrollbars.forEach(
					(el) => new window.OverlayScrollbars(el, {
				      scrollbars: {
				        autoHide: 'leave',
				        autoHideDelay: 200
				      }
				    })
				);
				if(e[1]) {
					e[1];
					console.log(e[1]);
				}
			}
		})
	}
}

var chart_issue
var chart_lln

function apex_issue(e) {
	var issue_chart = document.querySelector("#issue_chart");
	var issue_data = document.getElementById("issue_data");
	if (issue_data && issue_chart) {
		var data = JSON.parse(issue_data.value);
		var issue_options = {
			chart: {
				type: "donut"
			},
	  		labels: data[0],
			series: data[1],
			colors: data[2],
			plotOptions: {
	          pie: {
	            donut: {
	              labels: {
	                show: true,
	                total: {
	                  showAlways: true,
	                  show: true
	                }
	              }
	            }
	          }
	        },
		}
		chart_issue = new ApexCharts(issue_chart, issue_options);

		chart_issue.render();
	};
}	

function apex_lln(e) {
	var lln_chart = document.querySelector("#lln_chart");
	var lln_data = document.getElementById("lln_data");

	if (lln_data && lln_chart) {
		var data = JSON.parse(lln_data.value);
		var options = {
			chart: {
			    height: 150,
			    width: "100%",
			    type: "area"
			},
			series: [{
				name: "LPPI",
				data: data[1]
			}, {
				name: "LKAI",
				data: data[2]
			}, {
				name: "NHI",
				data: data[3]
			}],
	  		xaxis: {
	  			categories: data[0],
		  		labels: {
		  			show: false
		  		}
	  		},
	  		yaxis: {
					show: false
				},
				grid: {
				show: false
			},
			dataLabels: {
					enabled: false
				}
		}
		chart_lln = new ApexCharts(lln_chart, options);

		chart_lln.render();
	};
}

document.addEventListener('turbolinks:load', function() {
	list.forEach(tampilin);

	apex_issue();
	

	var target = document.getElementById("lln_card");
	if(target) {
		Rails.ajax({
			url: `/home/lln_card`,
			type: "get",
			success: function(data) {
				var content = new XMLSerializer().serializeToString(data);
				target.innerHTML = content;
				var tooltips = document.querySelectorAll("#tooltip");
				tooltips.forEach( 
					(element) => new bootstrap.Tooltip(element)
				);
				apex_lln();
			}
		})
	}

});


document.addEventListener("turbolinks:before-cache", function() {
	if(chart_issue) {
		chart_issue.destroy();
	};
	if(chart_lln) {
		chart_lln.destroy();
	}
});