document.addEventListener("turbolinks:load", function() {
	
	const in_check = document.querySelector('#in_media_check');
	const ex_check = document.querySelector('#ex_media_check');
	const in_field = document.querySelector('#in_media_field');
	const ex_field = document.querySelector('#ex_media_field');

	if(in_check !== null) {
		in_check.addEventListener("change", () => {
			initCheck(in_check, in_field);
		});
	}
	if(ex_check !== null) {
		ex_check.addEventListener("change", () => {
			initCheck(ex_check, ex_field);
		});
	}

});

function initCheck(check, field) {
	if(check.checked == true) {
		field.style.display = "block";
		// console.log("show");
	} else {
		field.style.display = "none"
		// console.log("hide");
		clearField(field);
	}

};

function clearField(field) {
	inputs = field.getElementsByTagName('input');
	for (index = 0; index < inputs.length; ++index) {
	    if(inputs[index].type =="text")
        inputs[index].value = '';
    }	
}