
var fp
var fpt
document.addEventListener("turbolinks:load", function() {
	fp = flatpickr(".datetimepicker",
		{
		    altInput: true,
		    altFormat: "j F Y",
		    dateFormat: "Y-m-d"
		}
	);
	fpt = flatpickr(".timepicker",
		{
		    enableTime: true,
		    dateFormat: "Y-m-d H:i",
		}
	);	
});
document.addEventListener("turbolinks:before-cache", function() {
	if(fp.length != 0) {
		if(Array.isArray(fp)) {
			fp.forEach(element => {
				element.destroy();
			})
		} else {
			fp.destroy();
		};

	};
	if(fpt.length != 0) {
		if(Array.isArray(fpt)) {
			fpt.forEach(element => {
				element.destroy();
			})
		} else {
			fpt.destroy();
		};

	};	
});