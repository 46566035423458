// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import * as bootstrap from 'bootstrap'
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
import "../src/OverlayScrollbars.min"
import "../src/fontawesome.min"
import "../src/theme"
import "../src/choices.min"
import "../src/choices_select"
import "../src/location_select"
import "../src/maps"
import "../src/heatmap"
import "../src/heatmapinfo"

require("trix")
require("@rails/actiontext")

import "../src/notyf.min"
import "../src/notification"

import "../src/flatpickr"
import "../src/datetime"

require('./nested-forms/addFields')
require('./nested-forms/removeFields')

import "../src/check_lppi"
import "../src/select_lkai"
import "../src/select_nhi"
import "../src/select_ni"
import "../src/select_report"

import "../src/animate"

import "../src/dashboard"

import Highcharts from 'highcharts';
// require("highcharts/modules/data")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
// require("highcharts/modules/offline-exporting")(Highcharts)
require("highcharts/modules/networkgraph")(Highcharts)
window.Highcharts = Highcharts;

import "../src/autocomplete"

import autoComplete from '@tarekraafat/autocomplete.js';
window.autoComplete = autoComplete;

import vis from 'visjs-network';
window.vis = vis;

import "../src/modal"

FontAwesome.config.mutateApproach = 'sync'