// for spider autocomplete
var autoCompleteArr = [];
document.addEventListener('turbolinks:load', function() {
	var autoCompletes = document.querySelectorAll(".autoComplete");
	var id = document.querySelector("#spider_id");
	if(autoCompletes) {
		autoCompletes.forEach(obj => {
			var parameter = obj.id.replace("spider_", "");
			// console.log(parameter);
	        var autoCompleteJS = new autoComplete({
	        	selector: `#${obj.id}`,
	            placeHolder: "Type to Search",
	            threshold: 3,
	            debounce: 300,
	            data: {
				    src: async (query) => {
				      try {
				        // Fetch Data from external Source
				        const source = await fetch(`/spiders/list?parameter=${parameter}&value=${query}&id=${id.value}`);
				        // Data is array of `Objects` | `Strings`
				        const data = await source.json();

				        return data;
				      } catch (error) {
				        return error;
				      }
				    },
				    // Data 'Object' key to be searched
				    keys: [parameter]
				},
	            resultItem: {
	                highlight: true
	            },
	            events: {
	                input: {
	                    selection: (event) => {
	                        const selection = event.detail.selection.value[event.detail.selection.key];
	                        autoCompleteJS.input.value = selection;
	                    }
	                }
	            }
	        });
	        autoCompleteArr.push(autoCompleteJS);
		})

	}
}
)

document.addEventListener("turbolinks:before-cache", function() {
	if(autoCompleteArr) {
		autoCompleteArr.forEach(obj => {
  			obj.unInit();

		})
		autoCompleteArr = [];
	}
}
)
// end spider autocomplete