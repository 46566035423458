
  let markers = [];
  
  //Map Index
  document.addEventListener('turbolinks:load', function(){
    if(document.getElementsByClassName("latlng")[0] != null) {
      var latlngs = JSON.parse(document.getElementsByClassName("latlng")[0].value);
      if(latlng === "") {
        latlng = {"Home": [-7.203658, 112.734049]};
      }
    };     
    if(document.getElementById("mapIndex") != null) {
      initMapIndex(latlngs, document.getElementById("mapIndex"))
    };

  });  

  function initMapIndex(latlngs, div) {
    var latlng = new google.maps.LatLng(-7.203658, 112.734049);
    var options = {
      center: latlng,
      zoom: 14
    };
    var map = new google.maps.Map(div, options);
    var bounds = new google.maps.LatLngBounds();
    for(var k in latlngs) {
      var latlng = new google.maps.LatLng(latlngs[k][0], latlngs[k][1]);
      const title = latlngs[k][2];
      const desc = "<center><h3>"+latlngs[k][2]+"</h3></center><p>NPWP: "+latlngs[k][3]+"</p><p>Address: "+latlngs[k][4]+"</p>";
      setMarker(latlng, map, desc, title);
      bounds.extend(latlng);
    };
    map.fitBounds(bounds);

    const rows = document.querySelectorAll(
      ".maplocate"
    );
    rows.forEach((element)=> {
      element.addEventListener("click", (event)=> {
        map.panTo(markers[element.id].getPosition());
        markers[element.id].info.open(map, markers[element.id]);
        map.setZoom(10);
      });
    });

    var reset = document.getElementById("resetMap");

    reset.addEventListener("click", (event)=> {
      map.fitBounds(bounds);
      markers.forEach((el)=> {
        try {
          el.info.close(map, el);
        } catch(e) {
        }
      });
    });

  };

  //Map Show
  document.addEventListener('turbolinks:load', function(){
    if(document.getElementsByClassName("lat")[0] != null) {
      var lat = document.getElementsByClassName("lat")[0].value;
      var lng = document.getElementsByClassName("lng")[0].value;
      if(lat === "") {
        lat = -7.203658;
        lng = 112.734049;
      }
    };    
    if(document.getElementById("mapShow") != null) {
      initMapShow(lat, lng, document.getElementById("mapShow"))
    };

  });  

  function initMapShow(lat, lng, div) {
    var latlng = new google.maps.LatLng(lat, lng);
    var options = {
      center: latlng,
      zoom: 12
    };
    var map = new google.maps.Map(div, options);

    pinMarker(latlng, map);
  };

  //Map Form
  document.addEventListener('turbolinks:load', function(){
    if(document.getElementsByClassName("lat")[0] != null) {
      var lat = document.getElementsByClassName("lat")[0].value;
      var lng = document.getElementsByClassName("lng")[0].value;
      if(lat === "") {
        lat = -7.203658;
        lng = 112.734049;
      }
    };
    if(document.getElementById("mapForm") != null) {
      initMapForm(lat, lng, document.getElementById("mapForm"))
    }
  });

  function initMapForm(lat=-7.203658, lng=112.734049, div) {

    var latlng = new google.maps.LatLng(lat, lng);
    var options = {
      center: latlng,
      zoom: 12
    };
    var map = new google.maps.Map(div, options);

    pinMarker(latlng, map);

    map.addListener("click", (event)=> {
      pinMarker(event.latLng, map);
      if(lat != null) {
        document.getElementsByClassName("lat")[0].value = event.latLng.lat();
        document.getElementsByClassName("lng")[0].value = event.latLng.lng();
      };
    });

    var myLocButton = document.getElementById("myLocation");
    var divAlert = document.getElementById("locAlert");

    myLocButton.addEventListener("click", (event)=> {
      getLocation();
    });

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        divAlert.innerHTML = "Geolocation is not supported by this browser.";
      }
    }
    function showPosition(position) {
      document.getElementsByClassName("lat")[0].value = position.coords.latitude;
      document.getElementsByClassName("lng")[0].value = position.coords.longitude;
      var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      pinMarker(latlng, map);
    }

    var resetButton = document.getElementById("resetButton");
    
    resetButton.addEventListener("click", (event)=> {
      pinMarker(latlng, map);
      if(lat != null) {
        document.getElementsByClassName("lat")[0].value = latlng.lat();
        document.getElementsByClassName("lng")[0].value = latlng.lng();
      };      
    });    
  };

  function pinMarker(latlng, map) {
    removeMarker(markers);
    const marker = new google.maps.Marker({
        position: latlng,
        map: map
    });
    markers.push(marker);
    map.panTo(marker.getPosition());
  };

  function setMarker(latlng, map, desc, title) {

    const marker = new google.maps.Marker({
        position: latlng,
        map: map,
        title: title
    });
    marker.info = new google.maps.InfoWindow({
      content: desc,
    });
    marker.addListener("click", ()=>{
      marker.info.open(map, marker);
    });
    markers.push(marker);
  };

  function removeMarker(markers) {
    for(let i = 0; i < markers.length; i++) {
      markers[i].setMap(null)
    };
  };

  
