document.addEventListener("turbolinks:load", function() {

	const lkai_select = document.querySelector('#ni_lkai_id');
	const iframe = document.querySelector('#iframe');

	if(lkai_select !== null) {
		value = lkai_select.options[lkai_select.selectedIndex].value;
		text = lkai_select.options[lkai_select.selectedIndex].text;
		replaceLink(value, text, iframe);
		lkai_select.addEventListener("change", () => {
			value = lkai_select.options[lkai_select.selectedIndex].value;
			text = lkai_select.options[lkai_select.selectedIndex].text;
			replaceLink(value, text, iframe);
		});
	}	
});

function replaceLink(value, text, iframe) {
	link = document.getElementById('lkai_print');
	link.href = `/lkais/${value}/print.pdf`;
	link.target = '_blank';
	link.text = `Show New Tab`;
	iframe.src = `/lkais/${value}/print.pdf`;
	if(value === "") {
		link.href = `#`;
		link.text = `Select First`;
		link.target = '_blank';
		iframe.src = ''
	}
	// console.log("replace value ")
}