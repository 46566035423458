document.addEventListener("turbolinks:load", function() {
	
	const rec_lkai_select = document.querySelector('#lkai_recommendation');
	const rec_lkai_field = document.querySelector('#lkai_rec_field');

	if(rec_lkai_select !== null) {
		rec_lkai_select.addEventListener("change", () => {
			initSelect(rec_lkai_select, rec_lkai_field)
		});
	}

	const lppi_select = document.querySelector('#lkai_lppi_id');
	const iframe = document.querySelector('#iframe');

	if(lppi_select !== null) {
		value = lppi_select.options[lppi_select.selectedIndex].value;
		text = lppi_select.options[lppi_select.selectedIndex].text;
		replaceLink(value, text, iframe);
		lppi_select.addEventListener("change", () => {
			value = lppi_select.options[lppi_select.selectedIndex].value;
			text = lppi_select.options[lppi_select.selectedIndex].text;
			// initSelect(rec_lkai_select, rec_lkai_field);
			replaceLink(value, text, iframe);
		});
	}

});

function initSelect(select, field) {
	if(select.options[select.selectedIndex].text !== "NHI" && select.options[select.selectedIndex].text !== "NI" ) {
		field.style.display = "block";
		// console.log(select.options[select.selectedIndex].text);
		changeLabel(select, field);
	} else {
		field.style.display = "none"
		// console.log(select.options[select.selectedIndex].text);
		clearField(field);
	}
}

function clearField(field) {
	inputs = field.getElementsByTagName('textarea');
	for (index = 0; index < inputs.length; ++index) {
        inputs[index].value = '';
    }	
}

function changeLabel(select, field) {
	label = field.getElementsByTagName('label');
	for (index = 0; index < label.length; ++index) {
        label[index].innerText = select.options[select.selectedIndex].text;
    }	
}

function replaceLink(value, text, iframe) {
	link = document.getElementById('lppi_print');
	link.href = `/lppis/${value}/print.pdf`;
	link.target = '_blank';
	link.text = `Show in New Tab`;
	iframe.src = `/lppis/${value}/print.pdf`;
	if(value === "") {
		link.href = `#`;
		link.text = `Select First`;
		link.target = '_blank';
		iframe.src = ''
	}
	// console.log("replace value ")
}