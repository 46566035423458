document.addEventListener("turbolinks:load", function() {

	const st_select = document.querySelector('#report_st_id');
	const iframe = document.querySelector('#iframe');

	if(st_select !== null) {
		value = st_select.options[st_select.selectedIndex].value;
		text = st_select.options[st_select.selectedIndex].text;
		replaceLink(value, text, iframe);
		st_select.addEventListener("change", () => {
			value = st_select.options[st_select.selectedIndex].value;
			text = st_select.options[st_select.selectedIndex].text;
			replaceLink(value, text, iframe);
		});
	}	
});

function replaceLink(value, text, iframe) {
	link = document.getElementById('st_print');
	link.href = `/sts/${value}/print.pdf`;
	link.target = '_blank';
	link.text = `Show New Tab`;
	iframe.src = `/sts/${value}/print.pdf`;
	if(value === "") {
		link.href = `#`;
		link.text = `Select First`;
		link.target = '_blank';
		iframe.src = ''
	}
	// console.log("replace value ")
}