import Rails from "@rails/ujs";
window.Rails = Rails;

var choices_prov
var choices_kab
var choices_kec
var choices_kel

function changeSelection(element, id, controller, select_id) {
  const select = document.querySelector(
    select_id
  );
  Rails.ajax({
    url: "/"+controller+"s/list",
    type: "get",
    data: "id="+id,
    success: function(data) {
      var options = "<option>Please select</option>";
      var values = [];
      for(var k in data) {
        options += "<option value="+data[k].id+">"+data[k].name+"</option>";
        values.push({value: data[k].id, label: data[k].name}); 
      };
      try {
        select.innerHTML = options;
        values.push({value: "x", label: "Please select"});
        element.setValue(values);
      }
      catch(err) {
        console.log(select_id+" tidak ditemukan")
      };

    },
    error: function(data) {
      alert(data);
    }
  })
}

document.addEventListener('turbolinks:load', function() {
  // selector provinsi
	const prov = document.querySelector(
    "#prov_select"
  );
  if(prov != null) {
    choices_prov = new Choices('#prov_select',
      {
        searchEnabled: true,
        searchChoices: true
      }
  );
    prov.addEventListener("change", (event) => {
      event.preventDefault();
      var id = prov.options[prov.selectedIndex].value;

      choices_kab.clearStore();
      choices_kec.clearStore();
      choices_kel.clearStore();
      changeSelection(choices_kab, id, "kab", "#kec_kab_id");
    });

  };

  // selector kabupaten
  const kab = document.querySelector(
    "#kec_kab_id"
  );
  if(kab != null) {
  choices_kab = new Choices('#kec_kab_id',
      {
        searchEnabled: true,
        searchChoices: true
      }
  );
    kab.addEventListener("change", (event) => {
      event.preventDefault();

      var id = kab.options[kab.selectedIndex].value;

      choices_kec.clearStore();
      choices_kel.clearStore();
      changeSelection(choices_kec, id, "kec", "#kel_kec_id");
    });
  };
  // });

  // selector kecamatan company
  const kec = document.querySelector(
    "#kel_kec_id"
  );
  if(kec != null) {
    
    choices_kec = new Choices('#kel_kec_id',
        {
          searchEnabled: true,
          searchChoices: true
        }
    );
    choices_kel = new Choices('#kel_id',
        {
          searchEnabled: true,
          searchChoices: true
        }
    );
    kec.addEventListener("change", (event) => {
      event.preventDefault();


      var id = kec.options[kec.selectedIndex].value;

      choices_kel.clearStore();
      changeSelection(choices_kel, id, "kel", "#kel_id");
    });

  };


})

document.addEventListener("turbolinks:before-cache", function() {
  
  const prov = document.querySelector(
    "#prov_select"
  );
  if(prov != null) {
    // console.log("choices_p destroyed");
    choices_prov.destroy();
  }

  const kab = document.querySelector(
    "#kec_kab_id"
  );
  if(kab != null) {
    // console.log("choices_c destroyed");
    choices_kab.destroy();
  }

  const kec = document.querySelector(
    "#kel_kec_id"
  );
  if(kec != null) {
    choices_kec.destroy();
    choices_kel.destroy();
  }

})