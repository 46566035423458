document.addEventListener("turbolinks:load", function() {
  const elements = document.querySelectorAll('#animated');
  elements.forEach(
    (element, index) =>  element.classList.remove('d-none')
  );
  elements.forEach(
    (element, index) =>  element.classList.add('animate__animated', 'animate__fadeInDown')
  );
}
);

document.addEventListener("turbolinks:before-visit", function() {
  const elements = document.querySelectorAll('#animated');
  elements.forEach(
    (element, index) =>  element.classList.remove('animate__animated', 'animate__fadeInDown')
  );
  elements.forEach(
    (element, index) =>  element.classList.add('d-none')
  );
}
);
