
document.addEventListener("turbolinks:load", function() {
  var notyf = new Notyf(
    {
      duration: 5000,
      position: {
        x: 'center',
        y: 'top',
      },
      ripple: true,
      dismissible: true
    }
  );

  const notifications = document.querySelectorAll("#notification");
  // Display an notification
  notifications.forEach((el) => {
    var content = el.innerHTML; 
    var type = el.className;
    if(content.length != 0) {
      if(type == "notice") {
        notyf.success(content);
      } else {
        notyf.error(content);
      }
    }
    // console.log(content.length);
  })

})
