let map, heatmap;
let datas = [];

document.addEventListener('turbolinks:load', function() {
  if (document.getElementById("data_koordinat") != null){
    var data = JSON.parse(document.getElementById("data_koordinat").value);
  
    for(var k in data) {
      // console.log(data[k][0]);
        datas.push(new google.maps.LatLng(data[k][0], data[k][1]));
    }
    initMap(datas);
  };
});

function initMap(data) {
  map = new google.maps.Map(document.getElementById("map"), {
    zoom: 7,
    center: { lat: -7.203658, lng: 112.734049 },
    // mapTypeId: "roadmap",
  });
  heatmap = new google.maps.visualization.HeatmapLayer({
    data: datas,
    map: map,
    radius: 20,
  });
}