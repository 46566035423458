var host = window.location.origin;
var choices_p
var choices_c
var choices_o
var choices_lists = new Array
// var choices_tp
document.addEventListener("turbolinks:load", function() {

  const company_type = document.querySelector('.choices_general');
  if(company_type != null) {
    choices_tp = new Choices('.choices_general',
        {
          searchEnabled: true,
          searchChoices: true
        }
    );
  }

  const person = document.querySelector('#person_ids');
  if(person != null) {
    // console.log("choices_p initialize");
    choices_p = new Choices(person, 
      {
        silent: true,
        duplicateItemsAllowed: false, 
        searchFloor: 3, 
        searchResultLimit: 4, 
        loadingText: 'Loading...',
        noResultsText: `No results found <b><a href="${host}/people/new" target="_blank">Add New Person?</a></b>`,
      });
    var valueList = JSON.parse(document.querySelector('#person_selected').value);
    // console.log(valueList)
    choices_p.setValue(valueList);
    choices_p.passedElement.element.addEventListener(
        'search',
        function(event) {
          // console.log(event.detail.value);
          fetch('/people/list?name='+event.detail.value).then(function(res) {
            return res.json();
          })
          .then(function(data) {
            return data.map(function(data) {
              return { label: data.name+" ("+data.identity_type+"-"+data.identity+")", value: data.id };
            });
          }).then(function(data) {
            choices_p.clearChoices();
            choices_p.setChoices(data);
            // console.log(data);
          });
        }
    )  
  }

  const company = document.querySelector('#company_ids');
  if(company != null) {
    choices_c = new Choices(company, 
      {
        silent: true,
        duplicateItemsAllowed: false, 
        searchFloor: 3, 
        searchResultLimit: 4, 
        loadingText: 'Loading...',
        noResultsText: `No results found <b><a href="${host}/companies/new" target="_blank">Add New Company?</a></b>`,
      });
    var valueList = JSON.parse(document.querySelector('#company_selected').value);
    // console.log(valueList)
    choices_c.setValue(valueList);    
    choices_c.passedElement.element.addEventListener(
        'search',
        function(event) {
          // console.log(event.detail.value);
          fetch('/companies/list?name='+event.detail.value).then(function(res) {
            return res.json();
          })
          .then(function(data) {
            return data.map(function(data) {
              return { label: data.name+" (NPWP: "+data.npwp+")", value: data.id };
            });
          }).then(function(data) {
            choices_c.clearChoices();
            choices_c.setChoices(data);
            // console.log(data);
          });
        }
    )
  }

  const office = document.querySelector('#office_ids');
  if(office != null) {
    choices_o = new Choices(office, 
      {
        silent: true,
        duplicateItemsAllowed: false, 
        searchFloor: 3, 
        searchResultLimit: 4, 
        loadingText: 'Loading...',
        noResultsText: `No results found`,
      });
    var valueList = JSON.parse(document.querySelector('#office_selected').value);
    console.log(valueList)
    choices_o.setValue(valueList);    
    choices_o.passedElement.element.addEventListener(
        'search',
        function(event) {
          console.log(event.detail.value);
          fetch('/offices/list?name='+event.detail.value).then(function(res) {
            return res.json();
          })
          .then(function(data) {
            return data.map(function(data) {
              return { label: data.name+" (Code: "+data.code+")", value: data.id };
            });
          }).then(function(data) {
            choices_o.clearChoices();
            choices_o.setChoices(data);
            console.log(data);
          });
        }
    )
  }

  const elements = document.querySelectorAll('.js-choice');
  if(elements) {
    elements.forEach(element => {
      const choice = new Choices(element);
      choices_lists.push(choice);
    })
  };

});

document.addEventListener("turbolinks:before-cache", function() {
  const person = document.querySelector('#person_ids');
  if(person != null) {
    // console.log("choices_p destroyed");
    choices_p.destroy();
  }

  const company = document.querySelector('#company_ids');
  if(company != null) {
    // console.log("choices_c destroyed");
    choices_c.destroy();
  }

  const office = document.querySelector('#office_ids');
  if(office != null) {
    // console.log("choices_o destroyed");
    choices_o.destroy();
  }

  const company_type = document.querySelector('.choices_general');
  if(company_type != null) {
    choices_tp.destroy();
  }

  if(choices_lists) {
    choices_lists.forEach(choice => {
      choice.destroy();
    })
  }

})